import FilterBoxCard from "./FilterBoxCard";
import FilterBoxRecommend from "./FilterBoxRecommend";
import styled from "styled-components";
import { Contains } from "@modules/utils/Contains";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
export default function FilterBox({
  className,
  searchSearchBoxRef,
  searchTextRef,
  searchData,
  setSearchData,
  searchBoxCloseHandler,
  searchKeywords,
  searchMetaData
}) {
  const {
    t
  } = useTranslation("common");
  const filterBoxRef = useRef();
  const [searchBtnFirstClick, setSearchBtnFirstClick] = useState(true);
  const bodyClickHandler = useCallback(event => {
    if (event.target.classList.contains("btnSearch") && searchBtnFirstClick) {
      setSearchBtnFirstClick(false);
      return;
    }
    if (searchSearchBoxRef) {
      if (!searchSearchBoxRef.current.contains(event.target) && !filterBoxRef.current.contains(event.target)) {
        searchTextRef.current.blur();
        if (searchBoxCloseHandler) searchBoxCloseHandler();
      }
    }
  }, []);
  const handler = (region_id, text, type, searchText) => {
    setSearchData({
      ...searchData,
      query: type === Contains.HOTEL ? text.split(',')[0] : text,
      searchId: region_id,
      searchType: type ? type : Contains.REGION,
      searchText: searchText
    });
    if (searchBoxCloseHandler) searchBoxCloseHandler();
  };
  useEffect(() => {
    document.body.addEventListener("click", bodyClickHandler);
    return () => {
      document.body.removeEventListener("click", bodyClickHandler);
    };
  }, []);
  return <FilterBoxDiv ref={filterBoxRef} className={`absolute top-[72px] left-0 mt-[12px] flex flex-col justify-start items-end flex-grow-0 flex-shrink-0
                 w-[440px] min-h-[342px] max-h-[520px] py-3.5 rounded-xl bg-white shadow-[0px_8px_20px_0_rgba(0,0,0,0.08)]
                 gap-[10px] overflow-x-hidden overflow-y-auto mobile:hidden z-10 ${className}`}>
      {!searchMetaData && <FilterBoxRecommend searchKeywords={searchKeywords} handler={handler} />}
      {!searchMetaData?.regions && !searchMetaData?.hotels || searchMetaData?.hotels.size === 0 && searchMetaData?.regions.size === 0 && <div className="flex flex-col flex-wrap justify-center items-center w-full h-[310px] text-Gray-300 tablet:text-Gray-500">
              {t('search.title.nodata')}
            </div>}
      {searchMetaData?.regions && searchMetaData?.regions.size > 0 && <FilterBoxCard type={Contains.REGION} searchData={searchData} dataList={searchMetaData?.regions} handler={handler} />}
      {searchMetaData?.hotels && searchMetaData?.hotels.size > 0 && <FilterBoxCard type={Contains.HOTEL} searchData={searchData} dataList={searchMetaData?.hotels} handler={handler} />}
    </FilterBoxDiv>;
}
const FilterBoxDiv = styled.div.withConfig({
  displayName: "FilterBox__FilterBoxDiv",
  componentId: "sc-190k9qc-0"
})(["::-webkit-scrollbar{width:12px;}::-webkit-scrollbar-thumb{background-color:#b3b3b3;background-clip:padding-box;border:4px solid transparent;border-radius:20px;}::-webkit-scrollbar-track{border-radius:20px;background-color:white;margin:12px 0;}"]);