import styles from './SearchHeader.module.css';
import DatePickerBox from './DatePickerBox';
import RoomCard from './RoomCard';
import { useEffect, useRef, useState } from 'react';
import FilterBox from './FilterBox';
import { getSearchMeta, getWebMain } from '@modules/api/Search';
import { useRouter } from 'next/router';
import { Contains } from '@modules/utils/Contains';
import { getRoomQuery, setSearchDateCookie, setSearchRoomsCookie } from '@modules/utils/SearchDataHandler';
import { useTranslation } from 'next-i18next';
import { disableBodyScrollLock, enableBodyScrollLock } from '@modules/utils/BodyScrollLock';
import dayjs from "dayjs";
export default function SearchHeader({
  className,
  searchData,
  setSearchData,
  isSearchClick,
  isMainDim,
  isTopZero
}) {
  // 다국어
  const {
    t
  } = useTranslation('common');
  const router = useRouter();
  const {
    locale
  } = useRouter();

  // dim
  const [viewState, setViewState] = useState({
    dim: false
  });
  const [webMain, setWebMain] = useState({});
  const searchSearchBoxRef = useRef();
  const roomsBoxRef = useRef();
  const [isFocusState, setIsFocusState] = useState({
    text: false,
    date: false,
    room: false
  });

  // 데이터 호출
  useEffect(() => {
    getWebMain(locale).then(data => setWebMain(data.body));
    roomSelectCompleteHandle(searchData.roomsInfo);
  }, [locale]);

  // 검색 버튼 핸들러 연결
  const searchBtnHandler = () => {
    if (!searchData.query || !searchData.searchId) {
      setIsFocusState({
        ...isFocusState,
        text: true
      });
      return;
    }
    disableBodyScrollLock();
    setIsFocusState({
      text: false,
      date: false,
      room: false
    });
    setSearchDateCookie(searchData.startDate, searchData.endDate);
    setSearchRoomsCookie(searchData.roomsInfo);
    const query = {
      'check-in': dayjs(searchData.startDate).format('YYYY.MM.DD'),
      'check-out': dayjs(searchData.endDate).format('YYYY.MM.DD'),
      ...getRoomQuery(searchData.roomsInfo),
      query: searchData.query,
      searchId: searchData.searchId,
      searchType: searchData.searchType,
      searchText: searchData.searchText
    };
    if (searchData.searchType === Contains.HOTEL) {
      router.replace({
        pathname: '/hotels/[id]',
        query: {
          id: searchData.searchId,
          ...query
        }
      });
    } else {
      setSearchData({
        ...searchData,
        query: searchData.query,
        searchId: searchData.searchId,
        searchType: searchData.searchType,
        searchBtnClick: !searchData.searchBtnClick
      });
      router.replace({
        pathname: '/search',
        query: query
      });
    }
  };

  // 검색어
  const [searchMetaData, setSearchMetaData] = useState(null);
  const searchTextRef = useRef();
  const searchTextHandle = e => {
    setSearchData({
      ...searchData,
      query: e.target.value,
      searchText: e.target.value
    });
  };
  useEffect(() => {
    let txtInputTimeout;
    if (searchData.query) {
      txtInputTimeout = window.setTimeout(() => {
        getSearchMeta(locale, {
          query: searchData.searchText || searchData.query,
          offset: 0,
          limit: 10,
          indices: [{
            name: 'meta_hotel'
          }, {
            name: 'meta_region'
          }]
        }).then(data => {
          setSearchMetaData(data.body);
        });
      }, 500);
    } else {
      setSearchMetaData(null);
    }
    return () => {
      clearTimeout(txtInputTimeout);
    };
  }, [searchData.query]);

  // 숙박일
  const handleCalendarClose = () => {
    setIsFocusState({
      ...isFocusState,
      date: false
    });
  };

  // 인원
  const [selectRoomCnt, setSelectRoomCnt] = useState(1);
  const [selectPeopleCnt, setSelectPeopleCnt] = useState(2);
  const roomSelectCompleteHandle = (roomsInfo, isClose) => {
    setSelectRoomCnt(roomsInfo?.length ?? 0);
    let peopleCnt = 0;
    roomsInfo?.map(info => {
      peopleCnt = peopleCnt + info.people.adultCnt + info.people.childs.length;
    });
    setSelectPeopleCnt(peopleCnt);
    if (isClose === true) {
      setSearchData({
        ...searchData,
        roomsInfo: roomsInfo
      });
      setIsFocusState({
        text: false,
        date: false,
        room: false
      });
    }
  };
  useEffect(() => {
    let timeout;
    if (isFocusState.text || isFocusState.date || isFocusState.room) {
      setViewState({
        ...viewState,
        dim: true
      });
      enableBodyScrollLock();
    } else {
      timeout = window.setTimeout(() => {
        setViewState({
          ...viewState,
          dim: false
        });
        disableBodyScrollLock();
      }, 120);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isFocusState]);
  useEffect(() => {
    if (isSearchClick?.clickItem === 'text') {
      setIsFocusState({
        ...isFocusState,
        text: true
      });
    } else if (isSearchClick?.clickItem === 'date') {
      setIsFocusState({
        ...isFocusState,
        date: true
      });
    } else if (isSearchClick?.clickItem === 'room') {
      setIsFocusState({
        ...isFocusState,
        room: true
      });
    } else if (isSearchClick?.clickItem === 'button') {
      searchBtnHandler();
    }
  }, [isSearchClick]);
  useEffect(() => {
    if (searchData?.showRoomsLayer) {
      setIsFocusState({
        ...isFocusState,
        room: true
      });
      setSearchData({
        ...searchData,
        showRoomsLayer: false
      });
    }
  }, [searchData?.showRoomsLayer]);
  return <>
      <div className={`fixed bottom-0 left-0 z-[5] h-full w-full tablet:hidden tablet:h-[calc(100%-129px)]
                    ${viewState.dim ? 'block' : 'hidden'}
                    ${isMainDim ? '' : 'bg-DimBlack-7'}`} />
      <div className={`reservDiv 
                    ${styles.reservDiv}
                    sticky z-[6] border-t border-solid border-y-Gray-50 bg-white tablet:top-[56px] tablet:hidden
                    ${isTopZero ? 'top-0' : 'top-[136px]'}
                    ${!viewState.dim && 'border-b'} 
                    ${className ?? ''}`}>
        <section className="search relative z-[2] mx-auto my-0 flex h-[72px] max-w-[1180px] flex-row items-center bg-white">
          <Box boxRef={searchSearchBoxRef} className={`box ${isFocusState.text && 'pl-[34px]'}`} isFocus={isFocusState.text}>
            <input type="text" className="relative z-[2] mt-[1px] inline-block h-auto h-[calc(100%_-_2px)] w-full w-[calc(100%_-_34px)] cursor-pointer overflow-ellipsis border-none pl-[32px] pr-0
         pt-[38px] pb-0 pl-0 pb-[9px] text-[16px] font-medium leading-normal tracking-[-0.002em] text-Gray-800 caret-Primary500 placeholder:text-[16px] placeholder:font-medium placeholder:tracking-[-0.002em] placeholder:text-Gray-800" placeholder={t('searchbar.search.placeholder')} ref={searchTextRef} value={searchData.searchText || ''} maxLength={50} onChange={searchTextHandle} onFocus={() => {
            // console.log("searchText focus");
            setIsFocusState({
              text: true,
              date: false,
              room: false
            });
          }} />
            <label className={`C_12_100_Medium absolute left-0 top-[16px] z-[2] text-Gray-500 opacity-100 
                         ${isFocusState.text && 'pl-[34px]'}`}>
              {t('searchbar.search.label')}
            </label>
            {isFocusState.text && <FilterBox searchSearchBoxRef={searchSearchBoxRef} searchTextRef={searchTextRef} searchData={searchData} setSearchData={setSearchData} searchBoxCloseHandler={() => {
            setIsFocusState({
              ...isFocusState,
              text: false
            });
          }} searchKeywords={webMain.search_keywords} searchMetaData={searchData.query && searchMetaData} />}
          </Box>
          <Box className="box flex-[1_1_326px] cursor-default" isFocus={isFocusState.date}>
            <DatePickerBox className={'inline-block h-auto w-full border-none pl-[32px] pr-0 pt-[36px] pb-[13px] text-[16px] font-medium leading-normal tracking-[-0.002em] text-Gray-800'} dateFormat={t('search.datepicker.format')} searchData={searchData} setSearchData={setSearchData} openHandler={() => {
            // console.log("datepicker focus");
            setIsFocusState({
              text: false,
              date: !isFocusState.date,
              room: false
            });
          }} closeHandler={handleCalendarClose} />
            <label className={`C_12_100_Medium absolute left-[32px] top-[16px] text-Gray-500 opacity-100`}>
              {t('searchbar.days.label')}
            </label>
          </Box>
          <Box boxRef={roomsBoxRef} className="box flex-[1_1_305px] before:w-[calc(100%-87px)] after:hidden" isFocus={isFocusState.room}>
            <p className="flex cursor-pointer items-center pl-[32px] pt-[36px] pb-[13px] text-center text-[16px] font-medium tracking-[-0.002em] text-Gray-800" onClick={() => {
            // console.log("roombox focus");
            setIsFocusState({
              text: false,
              date: false,
              room: !isFocusState.room
            });
          }}>
              {t('searchbar.people.txt', {
              n: {
                a: selectRoomCnt,
                b: selectPeopleCnt
              }
            })}
            </p>
            <label className={`C_12_100_Medium absolute left-[32px] top-[16px] text-Gray-500 opacity-100`}>
              {t('searchbar.people.label')}
            </label>
            {isFocusState.room && <RoomCard roomsBoxRef={roomsBoxRef} isRoomBoxFocus={isFocusState.room} defaultRoomInfo={searchData.roomsInfo} roomBoxCloseHandler={() => {
            setIsFocusState({
              ...isFocusState,
              room: false
            });
          }} roomSelectCompleteHandle={roomSelectCompleteHandle} />}
          </Box>
          <SearchBtn searchBtnHandler={searchBtnHandler}>검색</SearchBtn>
        </section>
      </div>
    </>;
}
const Box = ({
  className,
  boxRef,
  isFocus,
  children
}) => {
  const focus = isFocus ? 'before:content-[""] before:absolute before:left-0 before:top-0 before:w-full before:h-[72px] before:border-solid before:border-Gray-900 before:border-[1px] before:rounded-[100px] before:z-[2] tablet:before:hidden' : '';
  return <div ref={boxRef} className={`relative h-[72px] flex-[1_1_549px] after:absolute after:right-[-1px] after:top-[16px] after:h-[40px] after:w-px after:bg-Gray-50 after:content-[''] ${className} ${focus}`}>
      {children}
    </div>;
};
const SearchBtn = ({
  searchBtnHandler,
  children
}) => {
  return <a onClick={searchBtnHandler} className={'txtHide btnSearch ${className} absolute right-0 top-0 h-[72px] w-[7.4%] cursor-pointer bg-bg_btn_sch_h24 bg-[center_center] bg-no-repeat leading-[72px]'}>
      {children}
    </a>;
};